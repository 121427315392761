<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>
  <div id="maincontent_container">
      <!-- Sign Up -->
      
      <div>
        <p class="col-sm-12">If you are interested in becoming a Ma Labs customer, please fill out the following forms.</p>
        <p class="col-sm-12">If you have an incomplete form, <a href = "returnuser">click here</a>.</p> 

        <div class = "alert alert-danger" v-if="error">
          {{error}}
        </div>
        <div v-if= "message" class=" alert alert-success">
          {{message}}
        </div>

        <form @submit.prevent="handleSubmission">
          <div class = "row form-group col-sm-6">
            <label class = "fw-bold col-md-3 control-label">Email:</label>
            <input class = "col-md-9 form-control" maxlength="50" placeholder = "Email" type="email" required v-model="email">
          </div>

          <div class = "row form-group col-sm-6">
            <label class = "fw-bold col-md-3 control-label">Company:</label>
            <input class = "col-md-9 form-control" type="text" maxlength="30" placeholder = "Company" name="company" id="company" required v-model = company>
          </div>

          <div class = "col-md-9">
            <div class="radio">
              <label>
                <input type="radio" name = "type" required v-model="account_type" value = "0">
                Domestic Customer
              </label>
            </div>
            <div class = "radio">

              <label>
                <input type="radio" name = "type" required v-model = "account_type" value = "1">
                International Customer
              </label>
            </div>

          </div>


          <div>
            <label class = "fw-bold">
              <input type ="checkbox" name = "auth_confirm" v-model= "authorized" required>
              I am an authorized person to fill this form.
            </label>

          </div>

          <div id="form2" class = "fw-bold" style = "display: block;" v-if="authorized">
            <div class ="row">
              <div class = "col-sm-6">
                <div class = "form-group form group-sm">
                  <label class = "col-md-3 control-label">First Name</label>
                  <input class = "col-md-9 form-control input-sm" maxlength="30"  type = "text" placeholder="Your first name" required v-model = "firstName">
                  <label class = "col-md-3 control-label">Last Name</label>
                  <input class = "col-md-9 form-control input-sm" maxlength="30"  type = "text" placeholder="Your last name"  required v-model = "lastName">
                </div>
              
                <div class = "form-group form group-sm">
                  <label class = "col-md-3 control-label">Title</label>
                  <div class = "col-md-9">
                    <input class = "form-control input-sm" placeholder="Your title" maxlength="20"  required type="text" v-model="title">
                  </div>
                  
                </div>
              </div>
            </div>

            <div class = "row">
              <div class = "col-sm-6">
                <label class = "col-md-3 control-label"></label>
                <div class="col-md-9 required">
                  <label for="">Do you already have a sales rep assisting you? </label>
                </div>
              </div>

              <div class = "col-sm-6">
                <span class = "col-sm-3">
                  <input type="radio" name = "have_sales_rep" v-model = "sales" value = "1">
                   Yes 
                </span> 
                <span class = "col-sm-3">
                  <input type = "radio" name = "have_sales_rep"  v-model="sales" value = "0">
                  No   
                </span> 
              </div>
            </div>
          

            <div id="salesrep" class="row" v-if="sales ==  '1'"> 
              <div id="rep_info">
                <div class="col-sm-6">
                  <div class="form-group form-group-sm">
                    <label class = "col-md-3 control label" for="id_sales_first_name"> Sales First Name </label>
                    <div class = "col-md-9"> 
                      <input class = "form-control input-sm" maxlength="30"  name="sales_first_name" placeholder="Sales First Name" v-model= "salesFirst" type="text">
                    </div>
                  </div>

                  <div class = "form-group from-group-sm">
                    <label class = "col-md-3 control-label"> Sales Last Name</label> 
                    <div class = "col-md-9">
                      <input class = "form-control input-sm" maxlength="30"  name = "sales_last_name" placeholder = "Sales Last Name" v-model= "salesLast" type = "text">
                      
                    </div>
                  </div> 
                </div>

                <div class = "col-sm-6">
                  <div class = "form-group form-group-sm">
                    <label for="" class = "col-md-3 control label">Sales Email</label>
                    <div class = "col-md-9">
                      <input class="form-control input-sm" type="email" maxlength="50"  placeholder = "Sales Email" name = "sales_email" v-model= "salesEmail">
                    </div>
                  </div>
                </div>

              </div>



            </div>
          </div>

          <div class = "form-group">
            <button class="btn btn-primary pull-right" id = "submit" @click="handleSubmit">
              Sign Up
            </button>
          </div>

        </form>

      </div>


  </div>
</template>

<script>
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref } from 'vue'
import {getAPI} from '../../utils/axios-api.js'

export default {
  name: "AppSingUp",
  components: { BreadcrumbPage },
  data (){
    return{
      
    }
  },
  setup() {


    const authorized = ref(false)
    const sales = ref(0)

    const email = ref('')
    const company = ref('')
    const firstName = ref('')
    const lastName = ref('')
    const title = ref('')
    const account_type= ref('')
    const salesFirst = ref('')
    const salesLast = ref('')
    const salesEmail = ref('')

    const message = ref('')
    const error = ref('')


    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Home", link: "Home"})
    breadcrumb_links.value.push({text: "Become a reseller", link: "BecomeReseller"})
    breadcrumb_links.value.push({text: "Online Application", link: ""})

    const handleSubmit = () => {
      console.log('handleSubmit runs!')

      let payload = {
        "email" : email.value,
        "company" : company.value,
        "first_name" : firstName.value,
        "last_name" : lastName.value,
        "title" : title.value,
        "account_type": account_type.value,
        "sales_first_name" : salesFirst.value,
        "sales_last_name" :salesLast.value,
        "sales_email" :salesEmail.value,
      }

      getAPI
      .post('/application/signup/', payload)
      .then(response => {
        //check response data
        console.log("-----response data-----", response.data)
        
        message.value = response.data["message"],
        error.value = null
      })
      .catch(err => {
        //check error response 
        console.error(err.response)
        console.log(err.response.data)
        
        if(err.response.data["email"]){
          error.value = err.response.data["email"]
        }else{
          error.value = "An error has occurred, please try again."
        }

        console.log(error.value)

        // error.value = err.response.data["message"]
        
      
      })

    }


    return {
        breadcrumb_links,
        email,
        company,
        authorized,
        firstName,
        lastName,
        title,
        sales,
        salesFirst,
        salesLast,
        salesEmail,
        handleSubmit,
        account_type,
        error,

    }


  }
}
</script>

<style>

</style>